<div
  class="landing-template"
  [class.two-grid-temp-two]="
    templateDetails?.templateTextCode == 'LANDINGPAGE02' &&
    (templateDetails?.showLogo || templateDetails?.allowSkip)
  "
  [class.single-grid]="
  templateDetails?.templateTextCode == 'LANDINGPAGE02' &&
    !(templateDetails?.showLogo || templateDetails?.allowSkip)
  "
  [class.two-grid]="
  templateDetails?.templateTextCode == 'LANDINGPAGE01' &&
    !(templateDetails?.showLogo || templateDetails?.allowSkip)
  "
>
  <div
    class="logo-section"
    *ngIf="templateDetails?.showLogo || templateDetails?.allowSkip"
  >
    <ng-container
      *ngIf="templateDetails?.showLogo || templateDetails?.allowSkip"
    >
      <div class="img-container">
        <img
          [src]="restaurantLogo"
          alt="logo"
          *ngIf="templateDetails?.showLogo"
        />
      </div>
      <div
        class="close-btn"
        (click)="close($event)"
        *ngIf="templateDetails?.allowSkip"
      >
        <img src="assets/images/Cancel-mob.png" alt="cancel-icon" />
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="templateDetails?.templateTextCode == 'LANDINGPAGE01'">
    <div class="custom-scroll scroll-small landing-page-one">
      <div class="content-section">
        <ng-container
          *ngFor="let templateData of templateViewDetails; let indx = index"
        >
          <ng-container
            *ngIf="templateData.type == 'image'"
          >
            <div class="template-banner-img-section" *ngIf="!isMobileView">
              <img 
              *ngIf="!isMobileView && templateData.data.desktopUrl ? templateData.data.desktopUrl : templateData.data.url"
                class="banner-image"
                [src]="getImage(templateData.data)"
              />
              <div class="navigation-icon-section" *ngIf="!isMobileView">
                <div
                  class="left-nav-icon-section"
                  *ngIf="isPrevEnable"
                  (click)="templateNavigation($event, 'Prev')"
                >
                  <img
                    src="assets/images/left-icon.svg"
                    alt="left-nav-icon-img"
                  />
                </div>
                <ng-container
                  *ngIf="
                    isNextEnable && templateDetails?.pages?.length > 1;
                    else lastPageBtn
                  "
                >
                  <div
                    class="right-nav-icon-section"
                    (click)="templateNavigation($event, 'Next')"
                  >
                    <img
                      src="assets/images/right-icon.svg"
                      alt="right-nav-icon-img"
                    />
                  </div>
                </ng-container>
                <ng-template #lastPageBtn>
                  <div class="right-nav-icon-section" (click)="close($event)">
                    <img
                      src="assets/images/right-icon.svg"
                      alt="right-nav-icon-img"
                    />
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="templateData.type == 'header'">
            <div
              class="template-heading"
              [ngStyle]="{
                color: getColorForBlocks(templateData)
                  ? getColorForBlocks(templateData) 
                  : templateDetails.fontColor
              }"
            >
              <header>
                {{
                  templateData.data.text
                    | formattedLanguage: templateData.data.translation
                }}
              </header>
            </div>
          </ng-container>

          <ng-container *ngIf="templateData.type == 'paragraph'">
            <div class="template-content">
              <p
                [ngStyle]="{
                  color: getColorForBlocks(templateData)
                    ? getColorForBlocks(templateData)
                    : templateDetails?.fontColor
                }"
              >
                {{
                  templateData.data.text
                    | formattedLanguage: templateData.data.translation
                }}
              </p>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              templateData.type == 'link' &&
              templateData.data?.text &&
              templateData.data.url
            "
          >
            <div class="template-btn-section">
              <button
                class="btn-primary"
                [ngStyle]="{'color': getColorForBlocks(templateData),'background-color': getBackgroundColorForBlocks(templateData)}"
                (click)="goToUrl(templateData.data.url)"
              >
                {{
                  templateData.data.text
                    | formattedLanguage: templateData.data.translation
                }}
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div
      class="template-pagination"
      [ngStyle]="{
        color: getPageFontColor()
      }"
      *ngIf="templateDetails?.pages?.length > 1"
    >
      <div class="text-left">
        <span
          *ngIf="isMobileView && isPrevEnable"
          (click)="templateNavigation($event, 'Prev')"
          >Prev</span
        >
      </div>
      <div class="navigation-points" [ngStyle]="{ 'grid-template-columns': 'repeat('+templateDetails?.pages.length +', 1em)'}">
        <div
          *ngFor="let pageCount of templateDetails?.pages; let navIndx = index"
          [ngStyle]="setNavDotsStyle(navIndx)"
          (click)="templateNavigation($event, null, navIndx)"
        ></div>
      </div>
      <div class="text-right">
        <span
          *ngIf="isMobileView && isNextEnable"
          (click)="templateNavigation($event, 'Next')"
          >Next</span
        >
        <span *ngIf="isMobileView && !isNextEnable" (click)="close($event)"
          >Continue</span
        >
      </div>
    </div>
    <div
      *ngIf="templateDetails?.pages?.length == 1 && isMobileView"
      class="template-continue"
      [ngStyle]="{
        color: getPageFontColor()
      }"
      (click)="close($event)"
    >
      Continue
    </div>
  </ng-container>

  <ng-container *ngIf="templateDetails?.templateTextCode == 'LANDINGPAGE02'">
    <div class="custom-scroll scroll-small landing-page-two">
      <ng-container
        *ngFor="let templateData of templateViewDetails; let indx = index"
      >
        <ng-container *ngIf="templateData.type == 'image' ">
          <div class="template-banner-img-section" *ngIf="!isMobileView">
            <img 
            *ngIf="!isMobileView && templateData.data.desktopUrl ? templateData.data.desktopUrl : templateData.data.url"
            class="banner-image"
              [src]="getImage(templateData.data)"
            />
            <div class="navigation-icon-section" *ngIf="!isMobileView">
              <div
                class="left-nav-icon-section"
                *ngIf="isPrevEnable"
                (click)="templateNavigation($event, 'Prev')"
              >
                <img
                  src="assets/images/left-icon.svg"
                  alt="left-nav-icon-img"
                />
              </div>
              <ng-container
                *ngIf="
                  isNextEnable && templateDetails?.pages?.length > 1;
                  else lastPageBtn
                "
              >
                <div
                  class="right-nav-icon-section"
                  (click)="templateNavigation($event, 'Next')"
                >
                  <img
                    src="assets/images/right-icon.svg"
                    alt="right-nav-icon-img"
                  />
                </div>
              </ng-container>
              <ng-template #lastPageBtn>
                <div class="right-nav-icon-section" (click)="close($event)">
                  <img
                    src="assets/images/right-icon.svg"
                    alt="right-nav-icon-img"
                  />
                </div>
              </ng-template>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="templateData.type == 'header'">
          <div
            class="template-heading"
            [ngStyle]="{
              color: getColorForBlocks(templateData)
                ? getColorForBlocks(templateData)
                : templateDetails.fontColor
            }"
          >
            <header>
              {{
                templateData.data.text
                  | formattedLanguage: templateData.data.translation
              }}
            </header>
          </div>
        </ng-container>

        <!-- <ng-container *ngIf="templateData.type == 'paragraph'">
          <div class="template-content">
            <p
              [ngStyle]="{
                color: templateDetails?.fontColor
                  ? templateDetails?.fontColor
                  : '#000'
              }"
            >
              {{
                templateData.data.text
                  | formattedLanguage: templateData.data.translation
              }}
            </p>
          </div>
        </ng-container> -->

        <ng-container
          *ngIf="
            templateData.type == 'link' &&
            templateData.data?.text &&
            templateData.data.url
          "
        >
          <div class="template-btn-section">
            <button
              class="btn-primary"
              [ngStyle]="{'color': getColorForBlocks(templateData),'background-color': getBackgroundColorForBlocks(templateData)}"
              (click)="goToUrl(templateData.data.url)"
            >
              {{
                templateData.data.text
                  | formattedLanguage: templateData.data.translation
              }}
            </button>
          </div>
        </ng-container>
      </ng-container>

      <div
        class="template-full-pagination"
        [ngStyle]="{ padding: isMobileView ? '' : '2em 0' }"
        *ngIf="templateDetails?.pages?.length > 1"
        [style.color]="'white'"
      >
        <div class="text-left">
          <div
            *ngIf="isMobileView && isPrevEnable"
            class="nav-btn"
            [ngStyle]="{
              color: getPageFontColor()
            }"
            (click)="templateNavigation($event, 'Prev')"
          >
            Prev
          </div>
        </div>
        <div class="navigation-points" [ngStyle]="{ 'grid-template-columns': 'repeat('+templateDetails?.pages.length +', 1em)'}">
          <div
            *ngFor="
              let pageCount of templateDetails?.pages;
              let navIndx = index
            "
            [ngStyle]="setNavDotsStyle(navIndx)"
            (click)="templateNavigation($event, null, navIndx)"
          ></div>
        </div>
        <div class="text-right">
          <ng-container *ngIf="isMobileView && isNextEnable; else finalPage">
            <div
              class="nav-btn"
              [ngStyle]="{
                color: getPageFontColor()
              }"
              (click)="templateNavigation($event, 'Next')"
            >
              Next
            </div>
          </ng-container>
          <ng-template #finalPage>
            <div
              *ngIf="isMobileView"
              class="nav-btn"
              [ngStyle]="{
                color: getPageFontColor()
              }"
              (click)="close($event)"
            >
              Continue
            </div>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="templateDetails?.pages?.length == 1 && isMobileView"
        class="template-continue"
        [ngStyle]="{
          color: getPageFontColor()
        }"
        (click)="close($event)"
      >
        Continue
      </div>
    </div>
  </ng-container>
</div>
