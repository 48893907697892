import { OnInit, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  SetOrder,
  ClearOrderResponseMessage,
  PlaceOrder,
  OrderedUser,
  Order,
  Cart,
  CartState,
  CartItems,
  AdditionalParams,
  PaymentRequest,
  PaymentRequestType,
  Custom,
  PaymentProfile,
  Token,
  ClearPaymentDetails,
  PaymentMethodState,
  OrderState,
  Address,
  SweetAlertService,
  PushNotificationState,
  PushNotification,
  AuthEmailState,
  WhitelabelState,
  LocationState,
  AddressState,
  SetOrderDetails,
  SetMenuItem,
  ClearCart,
  DeliveryState,
  SavedCard,
  NewCard,
  SharedService,
  SetSelectedOrderId,
  VenueManagementService,
  VenueManagementState,
  environment,
  AckrooPaymentOrder,
  SetLoginFrom,
  RequestBillingAddress,
  ClearSelectedCard,
  SetPaymentMethod,
  OrderLevelModifierState,
  SetPaymentSelected,
  LocationDefaultState,
  LocationDefaults,
  ClearSelectedAddress,
  ClearDeals,
  ClearSelectedLocation,
  ClearLoginDetails,
  QRScanDetails,
  NavigationBackService,
  AuthState
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import {
  ProfileToken,
  OrderResponse,
  TimeInfo,
} from '@moduurnv2/libs-orderingapp/src/core/models/order.interface';
import { MessagingService } from '@moduurnv2/web/core/services/messaging-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentMethodModel } from '@moduurnv2/libs-orderingapp/src/core/state/payment-method.state';
import { PickupLocationUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/pickup-location-update-selection/pickup-location-update-selection.component';
import { RoomUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-update-selection/room-update-selection.component';
import { LoungeUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/lounge-update-selection/lounge-update-selection.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { paymentMethods,SpinnerTypes } from './../../../core/services/constant';
import { AddressListDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/address-list.component-desktop';
import * as publicIp from 'public-ip';
import { Location } from '@angular/common';
export class OrderCompleteBaseComponent implements OnInit {
  @Output() changePayMethod = new EventEmitter();
  @Select(CartState.getCart) cartPrice$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(OrderState.getOrderResponse) orderResponse$: Observable<
    OrderResponse
  >;
  @Select(PaymentMethodState.getSelectedPaymentType)
  selectedPaymentType$: Observable<string>;

  @Select(AddressState.getDeliveryAddress) selectedAddress$: Observable<
    Address
  >;
  @Select(PaymentMethodState.getPaymentState) paymentState$: Observable<
    PaymentMethodModel
  >;
  @Select(WhitelabelState.getOther) orgOtherConfigs$: Observable<any>;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  bannerImg = 'assets/images/order-complete-banner.png';
  phoneImg = 'assets/images/Phone-Icon-Big.svg';
  userDetailsForm: FormGroup;
  customerSelectedCard = {} as SavedCard;
  customerNewCard = {} as NewCard;
  selectedImage: string = null;
  showDropdown = false;
  canadaFlag = 'assets/images/Flag_of_Canada.svg';
  userDetails = {} as OrderedUser;
  orderDetails = {} as Order;
  pushNotification = {} as PushNotification;
  cartPrice: Cart;
  cartItems: CartItems[];
  specialInstructionText: String = '';
  deliveryAddress = {} as Address;
  cartOrder = {} as PlaceOrder;
  ackrooPaymentOrder = {} as AckrooPaymentOrder;
  selectedPaymentType: string;
  disableButton: boolean = false;
  formSubmitted: boolean = false;
  isMobileView: boolean;
  phoneNumber = {
    international: '',
    valid: undefined,
  };
  additionalParams = {
    payment: {
      locationId: '',
      organizationId: '',
      cartId: '',
      paymentRequest: {
        amount: 0,
        order_number: '',
        comments: '',
        custom: {
          ref1: '',
        } as Custom,
        token: {
          code: '',
          name: '',
        } as Token,
        payment_profile: {
          customer_code: '',
          card_id: '',
        } as PaymentProfile,
        billing: {
          // name : '',
          address_line1: '',
          // address_line2 :'',
          // city :'',
          // province :'',
          // country : '',
          postal_code: '',
          // phone_number :'',
          // email_address : '',
        } as RequestBillingAddress,
        customer_ip : '',
      } as PaymentRequestType,
      userId: '',
      paymentProfileId: '',
      payUsingSavedCard: false,
      profileToken: {
        token: {
          code: '',
          name: '',
        } as Token,
      } as ProfileToken,
      giftCardDetails: null,
      creditCardDetails: {
        cardNumber: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
        nameOnCard: '',
      },
    } as PaymentRequest,
    savedCard: false,
    saveToAccount: false,
    cardType: '',
    session: '',
    savedCardId: '',
  } as AdditionalParams;
  destroy$: Subject<boolean> = new Subject<boolean>();
  orderData = {
    amount: 0,
    paymentType: '',
    deliveryMethod: '',
    futureOrder: false,
    deliveryTime: '',
    cardNumber: '',
    cardType: '',
    paymentMethod: '',
    giftCardNumber: '',
    futureEndDate: undefined,
  };
  paymentState: PaymentMethodModel;
  bypassCustomerDetailsEntry: boolean = true;
  isBypassedDetailsOrderPlaced: boolean = false;
  zeroPriceOrder: boolean = false;
  orderSettings: any;
  qrScanDetails:QRScanDetails;
  selectedLocation;
  isEmployeeLoyaltyOrder : boolean = false;
  constructor(
    private fb: FormBuilder,
    protected store: Store,
    protected router: Router,
    public sweetAlert: SweetAlertService,
    public spinner: NgxSpinnerService,
    protected shared: SharedService,
    private venueManagementService: VenueManagementService,
    protected modal: ModalService,
    private messagingService: MessagingService,
    protected translate: TranslateService,  
    private _location: Location,
  ) {
    this.qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    this.selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    this.bypassCustomerDetailsWithoutManualEntry();
    this.locationDefaults$
    .pipe(takeUntil(this.destroy$))
    .subscribe((locationDefaults) => {
      if (locationDefaults) {
        this.orderSettings = locationDefaults.locationData.orderSettings;
      }
    });
    this.cartPrice$.pipe(takeUntil(this.destroy$)).subscribe((cartPrice) => {
      if (cartPrice) {
        this.cartPrice = cartPrice;
        this.cartPrice.totalAmount = this.cartPrice.price;
        if (this.cartPrice.price == 0  || this.orderSettings.allowNoPaymentOrdering) {
          this.zeroPriceOrder = true;
        } else {
          this.zeroPriceOrder = false;
        }
      }
    });
    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      if (cartItems) {
        this.cartItems = cartItems;
      }
    });
    this.selectedAddress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((address) => {
        if (address) {
          this.deliveryAddress = address;
        }
      });

    this.selectedPaymentType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedPaymentType) => {
        if (selectedPaymentType) {
          this.selectedPaymentType = selectedPaymentType;
        }
      });
    this.paymentState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentState) => {
        if (paymentState) {
          this.paymentState = paymentState;
        }
      });
    this.orderResponse$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderResponse) => {
        this.spinner.hide(SpinnerTypes.ORDER_COMPLETION);
        if (
          this.bypassCustomerDetailsEntry &&
          (orderResponse == undefined || orderResponse.order_id == '')
        ) {
          if (!this.isBypassedDetailsOrderPlaced) {
            let vm = this;
            let startedOrderProcessing = true;
            this.modal.showSmallModal(AlertPopupComponent, {
              data: this.dataPassedToPlaceOrderConfirm(),
              whenClosed: (data) => {
                if (data) {
                  if (startedOrderProcessing) vm.placeOrder();
                }
                startedOrderProcessing = false;
              },
            });
          }
        }
        if (orderResponse && orderResponse.order_id) {
          this.store.dispatch(new ClearPaymentDetails());
          this.store.dispatch(new ClearCart());
          this.store.dispatch(new SetMenuItem(null));
          this.store.dispatch(new SetSelectedOrderId(orderResponse.order_id));
          this.store.dispatch(new ClearOrderResponseMessage());
          this.store.dispatch(new SetPaymentSelected(false));
          this.router.navigate([`/order/success`]);
        } else if (
          orderResponse &&
          orderResponse.message != '' &&
          !this.isActionNeededOnPlaceOrderError(orderResponse.errorCode)
        ) {
          this.modal.showSmallModal(AlertPopupComponent, {
            data: {
              content: orderResponse.message,
              actionable: false,
              isHtmlContent: true,
            },
            whenClosed: (data) => {
              if (orderResponse.errorCode == '1403') this.userExit();
              else {
                const selectedLocation = this.store.selectSnapshot(
                  LocationState.getSelectedLocation
                );
                if (selectedLocation)
                  this.router.navigate([
                    `/locations/${selectedLocation._id}/order`,
                  ]);
              }
            },
          });
          this.store.dispatch(new ClearOrderResponseMessage());
        } else if (orderResponse.errorCode) {
          this.actionOnPlaceOrderError(orderResponse);
        }
        this.formSubmitted = false;
        this.disableButton = false;
      });
 
      let other = this.store.selectSnapshot(WhitelabelState.getOther);
      if(other.employeeLoyalty && other.employeeLoyalty.isAllow){
        this.isEmployeeLoyaltyOrder = true;
      }
      else{
        this.isEmployeeLoyaltyOrder = false;
      }
  }

  // Function to check any action is needed other than showing error message on receiving an error after place order
  isActionNeededOnPlaceOrderError(errorCode) {
    if (errorCode == '1402') {
      return true;
    }
    return false;
  }
  // Function to do an action after receiving error on place order.
  actionOnPlaceOrderError(orderResponse) {
    if (orderResponse && orderResponse.errorCode == '1402') {
      this.store.dispatch(new ClearOrderResponseMessage());
      this.store.dispatch(new SetLoginFrom('order'));
      this.router.navigate(['login']);
      this.sweetAlert.toast('error', orderResponse.message);
    }
  }

  ngOnInit() {
    let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    let guest = JSON.parse(localStorage.getItem('guest'));
    let isGuest = user && user.user_id ? false : true;
    if (isGuest && guest) {
      this.userDetails.name = guest.name;
      this.userDetails.phone = guest.phone;
    } else {
      this.userDetails.name =
        (user && user.fname ? user.fname : '') +
        (user && user.lname ? ' ' + user.lname : '');
      this.userDetails.phone =
        user && user.primaryPhone ? user.primaryPhone : '';
    }

    this.userDetailsForm = this.fb.group({
      name: [
        this.userDetails.name,
        [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)],
      ],
      // number: [
      //   this.userDetails.phone,
      //   this.phoneNumber.international == '' || this.phoneNumber.valid == true,
      // ],
      // acceptTerms: [false, Validators.requiredTrue],
    });
    this.selectedImage = this.canadaFlag;
    this.getCardDetails();
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel && whitelabel?.lastPage?.phone) {
      this.bannerImg = environment.imagebaseurl + whitelabel.lastPage.phone;
    }
  }

  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );   
    const url = this.router.url;      
    const previouslyNavigatedFrom = this.store.selectSnapshot(AuthState.getLoginFrom)
  //   if(previouslyNavigatedFrom=='payment'){      
  //   this.router.navigate(['payment'])
  //   this.store.dispatch(new SetLoginFrom(""))
  // }else{    
    if(selectedLocation && selectedLocation._id && (previouslyNavigatedFrom=="reviewPage/payment" || previouslyNavigatedFrom=="reviewPage")){            
          //  this.router.navigate([`locations/${selectedLocation._id}/order`]);
          if(this.zeroPriceOrder){
            this.router.navigate([`locations/${selectedLocation._id}/order`]);
          }
          else{
            this.router.navigate([`/payment`]);
          }
           this.store.dispatch(new SetLoginFrom("reviewPage"))
         } else if(previouslyNavigatedFrom=="locations") {          
           this.router.navigate(['/payment'])
        }else{
          this._location.back();
        }
    // }
  }
  changeView() {
    this.changePayMethod.emit(true);
  }
  onClick() {
    this.showDropdown = !this.showDropdown;
  }

  onSelect(event) {
    this.showDropdown = false;
    this.selectedImage = this.canadaFlag;
  }
  formattedPhone(event) {
    this.phoneNumber = event;
  }
  async checkAndPlaceOrder() {
    let vm = this;
    let startedOrderProcessing = true;
    let notification = localStorage.getItem('PushNotification')
      ? JSON.parse(localStorage.getItem('PushNotification'))
      : undefined;

    if (
      this.messagingService.checkForNotificationPopup() &&
      notification != 'denied'
    ) {
      await this.sweetAlert
        .allowPushNotification(
          this.translate.instant('order-update'),
          this.translate.instant('update-order-status'),
          false,
          this.translate.instant('allow'),
          this.translate.instant('deny')
        )
        .then((choice) => {
          if (choice) {
            return vm.messagingService.askPermission().then((response) => {
              this.modal.showSmallModal(AlertPopupComponent, {
                data: this.dataPassedToPlaceOrderConfirm(),
                whenClosed: (data) => {
                  if (data) {
                    if (startedOrderProcessing) vm.placeOrder();
                  }
                  startedOrderProcessing = false;
                },
              });
            });
          } else {
            localStorage.setItem('PushNotification', JSON.stringify('denied'));
            this.modal.showSmallModal(AlertPopupComponent, {
              data: this.dataPassedToPlaceOrderConfirm(),
              whenClosed: (data) => {
                if (data) {
                  if (startedOrderProcessing) vm.placeOrder();
                }
                startedOrderProcessing = false;
              },
            });
          }
        });
    } else {
      this.modal.showSmallModal(AlertPopupComponent, {
        data: this.dataPassedToPlaceOrderConfirm(),
        whenClosed: (data) => {
          if (data) {
            if (startedOrderProcessing) vm.placeOrder();
          }
          startedOrderProcessing = false;
        },
      });
    }
  }

  placeOrderConfirmationText() {
    let text = '';
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    console.log(this.translate.currentLang); // prints the current selected language;
    const locationAddress = selectedLocation.shortAddress
      ? selectedLocation.shortAddress
      : selectedLocation.address;
    text +=
      this.translate.instant('You are placing a') +
      ' ' +
      `<b>${selectedDelivery.text}</b>` +
      ' ' +
      this.translate.instant('order from') +
      ' ' +
      `<b>${selectedLocation.name}</b>` +
      ' ' +
      this.translate.instant('at') +
      ' ' +
      `<b>${locationAddress}</b>` +
      '. ';
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    let proceedQnTerm = 'proceed-question';
    if (
      selectedLocation &&
      selectedLocation.deliveryPartnerIntegration &&
      selectedLocation.deliveryPartnerIntegration.isAllow &&
      selectedDelivery.textCode == 'DELIVERYTYPE01'
    ) {
      let minTip =
        (this.cartPrice.subTotal - this.cartPrice.couponDiscount) * 0.15;

      if (this.cartPrice.tip < minTip) {
        text += `<br> <br><b style="color:red">Important</b><br><p>A minimum of 15% tip (${
          whitelabel.currency
        }${minTip.toFixed(
          2
        )}) is recommended to incentivize drivers to accept your order.</p>`;

       proceedQnTerm = 'still-proceed-question';
      }
    }
    text += `<p>` + this.translate.instant(proceedQnTerm) + `?</p>`;
    return text;
  }

  dataPassedToPlaceOrderConfirm() {
    const placeOrderHeading = this.placeOrderConfirmationText();
    return {
      header: placeOrderHeading,
      isHtmlHeader: true,
      proceedText: this.translate.instant('yes'),
      closeBtnText: this.translate.instant('no'),
      actionable: true,
    };
  }

  async confirmOrder() {
    this.store.dispatch(new SetPaymentSelected(true));
    if (!this.checkOrderLevelModifiers()) {
      return this.sweetAlert.toast(
        'error',
        'Sorry, Please select the required items to continue.'
      );
    }
    this.formSubmitted = true;
    if (
      this.userDetailsForm.valid &&
      this.phoneNumber.valid &&
      this.validateVenueSelection()
    ) {
      if (!this.isMobileView) await this.checkAndPlaceOrder();
      else {
        let vm = this;
        let startedOrderProcessing = true;
        this.modal.showSmallModal(AlertPopupComponent, {
          data: this.dataPassedToPlaceOrderConfirm(),
          whenClosed: (data) => {
            if (data) {
              if (startedOrderProcessing) vm.placeOrder();
            }
            startedOrderProcessing = false;
          },
        });
      }
    }
  }

  getCardDetails() {
    // if(this.paymentState.car)
    if (this.paymentState.cardType != '') {
      if (this.paymentState.payUsingSavedCard) {
        this.orderData.cardNumber = this.paymentState.selectedCard.number?.substring(
          this.paymentState.selectedCard.number.length - 4
        );
      } else {
        this.orderData.cardNumber = this.paymentState.newCard.number.substring(
          this.paymentState.newCard.number.length - 4
        );
      }
      this.orderData.cardType = this.paymentState.cardType;
    }
  }

  async placeOrder() {
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    const paymentGateway = other.paymentGateway;
    this.isBypassedDetailsOrderPlaced = true;
    this.spinner.show(SpinnerTypes.ORDER_COMPLETION);
    this.disableButton = true;
    const pushNotificationToken = this.store.selectSnapshot(
      PushNotificationState.getPushNotification
    );
    if (this.zeroPriceOrder && !this.isEmployeeLoyaltyOrder) {
      this.setCODOrder();
    }
    if(this.zeroPriceOrder && this.isEmployeeLoyaltyOrder){
      this.setLoyaltyOrder();
    }
    let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);

    this.userDetails.email = user && user.email ? user.email : '';
    this.userDetails.name = this.userDetailsForm?.value.name;
    if (
      this.phoneNumber?.international == '' ||
      this.phoneNumber?.valid == true
    ) {
      this.userDetails.phone = this.phoneNumber?.international
        ? this.phoneNumber.international
        : '';
    }
    if (user && user.user_id) {
      this.userDetails.userId = user && user.user_id ? user.user_id : '';
    } else {
      localStorage.setItem('guest', JSON.stringify(this.userDetails));
    }
    this.orderDetails.orderedBy = this.userDetails;
    this.orderDetails.orderVia = 'web';
    this.orderDetails.orderLater = false;
    this.orderDetails.cartId = this.cartPrice.cart_id;
    this.orderDetails.organizationId = this.cartItems[0].organizationId;
    this.orderDetails.specialInstructions = this.store.selectSnapshot(
      CartState.getSpecialInstruction
    );
    this.orderDetails.locationId = this.cartPrice.locationId;
    this.orderDetails.deliveryMethod = this.cartItems[0].deliveryMethod.textCode;
    this.orderData.deliveryMethod = this.cartItems[0].deliveryMethod.textCode;
    this.orderData.cardType = '';
    let deliveryTime = new Date().toISOString();
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
    if (futureDate) {
      deliveryTime = new Date(futureDate).toISOString();
      this.orderDetails.orderLater = true;
      this.orderData.futureOrder = true;
      this.orderData.deliveryTime = deliveryTime;
      if (this.orderDetails.deliveryMethod == 'DELIVERYTYPE10') {
        const futureEndDate = this.store.selectSnapshot(
          CartState.getFutureEndDateTime
        );
        const selectedVenue = this.store.selectSnapshot(
          VenueManagementState.getSelectedRoom
        );
        if (selectedVenue?.allowSchedule && futureEndDate)
          this.orderData.futureEndDate = futureEndDate;
      }
    }
    this.orderDetails.deliveryTime = deliveryTime;
    if (this.deliveryAddress && !this.isEmptyobject(this.deliveryAddress)) {
      this.orderDetails.deliveryAddress = this.deliveryAddress;
    }
    if (pushNotificationToken) {
      this.pushNotification.key = pushNotificationToken.token;

      const whitelabel = this.store.selectSnapshot(
        WhitelabelState.getWhitelabel
      );

      if (whitelabel && whitelabel.webOrderingUrl)
        this.pushNotification.click_action =
          whitelabel.webOrderingUrl + '/notification';
      if (whitelabel && whitelabel.restaurantLogo)
        this.pushNotification.icon =
          environment.imagebaseurl + whitelabel.faviconImage;
    }

    this.cartOrder.order = this.orderDetails;
    const paymentMethods = this.store.selectSnapshot(
      PaymentMethodState.getSelectedPayment
    );
    // this.selectedPaymentType = paymentMethods;
    this.cartOrder.paymentType = this.selectedPaymentType;
    this.cartOrder.locationId = this.cartPrice.locationId;
    this.cartOrder.pushNotification = this.pushNotification;
    this.orderData.paymentType = this.selectedPaymentType;
    this.orderData.amount = this.cartPrice.totalAmount;

    let paymentState = this.store.selectSnapshot(
      PaymentMethodState.getPaymentState
    );
    this.orderData.paymentMethod = 'payLater';
    if (this.selectedPaymentType == 'payNow') {
      this.orderData.paymentMethod = 'card';
      let isCardPayment = true;
      let session = this.cartPrice.cart_id + new Date().getTime();
      this.additionalParams.payment.giftCardDetails = {};

      if (paymentState.ackrooPaymentDetails) {
        'loyalty'; // What is this?
        this.ackrooPaymentOrder.giftCardDetails =
          paymentState.ackrooPaymentDetails;
        this.ackrooPaymentOrder.session = session;
        this.cartOrder.ackrooPaymentOrder = this.ackrooPaymentOrder;
        this.cartOrder.paymentType = 'payGiftAndLoyalty';

        if (paymentState.ackrooPaymentDetails.isAllAmountPayedFromGiftCard) {
          this.orderData.paymentMethod = 'giftLoyaltyOnly';
          isCardPayment = false;
        } else {
          this.orderData.paymentMethod = 'cardAndGiftLoyalty';
          this.additionalParams.payment.giftCardDetails = this.ackrooPaymentOrder.giftCardDetails;
        }
      } else if (paymentState.mealPaymentDetails) {
        this.cartOrder.paymentType = 'payMealProgram';
        this.orderData.paymentMethod = 'payMealProgram';
        isCardPayment = false;
        const mealCode = this.store.selectSnapshot(
          PaymentMethodState.getMealPaymentDetails
        );
        this.cartOrder.mealProgramOrder = {
          code: mealCode.code,
          email: user && user.email ? user.email : '',
        };
      }

      if (isCardPayment) {
        this.additionalParams.savedCard = false;
        this.additionalParams.payment.locationId = this.cartPrice.locationId;
        this.additionalParams.payment.organizationId = this.cartItems[0].organizationId;
        this.additionalParams.payment.cartId = this.cartPrice.cart_id;
        this.additionalParams.saveToAccount = paymentState.saveToAccount;

        this.additionalParams.payment.paymentRequest.amount = this.cartPrice.price;
        this.additionalParams.payment.paymentRequest.customer_ip = await publicIp.v4();;

        this.additionalParams.payment.paymentRequest.paymentGateway =
          paymentGateway.textCode;
          this.additionalParams.payment.payUsingSavedCard = (paymentState && paymentState.payUsingSavedCard  == true) ? true : false;
        if (
          (paymentGateway.textCode == 'PAYMENTGATEWAY04' || paymentGateway.textCode == 'PAYMENTGATEWAY05') &&
          !paymentState.payUsingSavedCard
        ) {
          this.additionalParams.payment.creditCardDetails.cardNumber =
            paymentState.newCard.number;
          this.additionalParams.payment.creditCardDetails.expiryMonth =
            paymentState.newCard.expiry_month;
          this.additionalParams.payment.creditCardDetails.expiryYear =
            paymentState.newCard.expiry_year;
          this.additionalParams.payment.creditCardDetails.cvv =
            paymentState.newCard.cvd;
          this.additionalParams.payment.creditCardDetails.nameOnCard =
            paymentState.newCard.name;
          this.additionalParams.payment.creditCardDetails.address =
            paymentState.newCard.address;
        }

        if (
          paymentGateway.textCode == 'PAYMENTGATEWAY02' &&
          !paymentState.payUsingSavedCard
        ) {
          let address = paymentState.newCard.address.split('|');
          this.additionalParams.payment.paymentRequest.billing.address_line1 =
            address[0];
          this.additionalParams.payment.paymentRequest.billing.postal_code =
            address[1];
        }

        this.additionalParams.payment.paymentRequest.order_number = session;
        this.additionalParams.payment.paymentRequest.comments = this.userDetails.name;
        this.additionalParams.payment.paymentRequest.custom.ref1 = JSON.stringify(
          this.userDetails
        );
        if ( paymentGateway.textCode == 'PAYMENTGATEWAY02' &&
        !paymentState.payUsingSavedCard) {
          if(paymentState.paymentDetails && paymentState.paymentDetails.token && paymentState.paymentDetails.token.code){
            this.additionalParams.payment.paymentRequest.token =
            paymentState.paymentDetails.token;
          }
          else{
            this.spinner.hide(SpinnerTypes.ORDER_COMPLETION);
           return this.modal.showSmallModal(AlertPopupComponent, {
              data: this.dataPassedToTokenIssue(),
              whenClosed: (data) => {
              },
            });
          }
        }
       

        this.additionalParams.cardType = paymentState.cardType
          ? paymentState.cardType.toUpperCase()
          : '';
        this.additionalParams.session = session;
        if (paymentState.payUsingSavedCard) {
          this.additionalParams.savedCard = true;
          this.additionalParams.payment.paymentRequest.payment_profile = {
            customer_code: paymentState.customerProfileId,
            card_id: paymentState.selectedCard.card_id,
          };
          if (paymentGateway.textCode == 'PAYMENTGATEWAY04') {
            this.additionalParams.savedCardId = paymentState.selectedCard._id;
          }
        }

        this.additionalParams.payment.paymentProfileId =
          paymentState.customerProfileId;
        if (
          paymentState.paymentDetails &&
          paymentState.paymentDetails.profileToken
        )
          this.additionalParams.payment.profileToken.token =
            paymentState.paymentDetails.profileToken;

        this.additionalParams.payment.userId = user && user.user_id;

        this.cartOrder.additionalParams = this.additionalParams;
      }
    } 
    else if(this.isEmployeeLoyaltyOrder){
      this.cartOrder.zeroPaymentOrder = {
        session: this.cartPrice.cart_id + new Date().getTime()
      }
      this.orderData.paymentMethod = 'loyalty';
      
      delete this.cartOrder.additionalParams;
    }else {
      delete this.cartOrder.additionalParams;
      delete this.cartOrder.zeroPaymentOrder;
    }
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const currDate = new Date();
    this.cartOrder.timeInfo = {
      locationId: this.cartPrice.locationId,
      type: delivery.textCode,
      currentTime: currDate.toISOString(),
      future: futureDate,
      timezoneOffset: currDate.getTimezoneOffset(),
      selectedTime: deliveryTime,
    };
    this.store.dispatch(new SetOrder(this.cartOrder));
    this.store.dispatch(new SetOrderDetails(this.orderData));
  }

  isEmptyobject(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  validateVenueSelection() {
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if (this.venueManagementService.isVenueManagementOrder(delivery.textCode) && this.validateQRScan()) {
      let selectedVenue = this.getVenueByDeliveryTypeTextCode(
        delivery.textCode
      );
      if (selectedVenue && selectedVenue.name) {
        return true;
      } else {
        this.forceUserSelectVenue(delivery.textCode);
        return false;
      }
    } else {
      return true;
    }
  }

  forceUserSelectVenue(deliveryType) {
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if(this.qrScanDetails?.venueName && selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == this.selectedLocation._id){
      this.sweetAlert.toast('error','Please try scanning qr once again.');
    } else 
    if (this.isMobileView) {
      this.modal.showModal(this.venueSelectionModalToShow(deliveryType));
    } else {
      this.modal.showMediumModalDesktop(
        this.venueSelectionModalToShow(deliveryType)
      );
    }
  }

  venueSelectionModalToShow(deliveryType) {
    switch (deliveryType) {
      case 'DELIVERYTYPE08':
        this.sweetAlert.toast('error', 'Please select a table to place order');
        return LoungeUpdateSelectionComponent;
      case 'DELIVERYTYPE09':
        this.sweetAlert.toast(
          'error',
          this.translate.instant('select-station-place-order')
        );
        return PickupLocationUpdateSelectionComponent;
      case 'DELIVERYTYPE10':
        this.sweetAlert.toast(
          'error',
          this.translate.instant('select-room-place-order')
        );
        return RoomUpdateSelectionComponent;
      default:
        break;
    }
  }

  getVenueByDeliveryTypeTextCode(deliveryType) {
    let selectedVenue;
    console.log('delivery type:', deliveryType);
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if(this.qrScanDetails?.venueName && selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == this.selectedLocation._id){
      selectedVenue = this.store.selectSnapshot(VenueManagementState.getSelectedVenue);
    } else {
      switch (deliveryType) {
        case 'DELIVERYTYPE08':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedVenue
          );
          break;
        case 'DELIVERYTYPE09':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedPickUpLocation
          );
          break;
        case 'DELIVERYTYPE10':
          selectedVenue = this.store.selectSnapshot(
            VenueManagementState.getSelectedRoom
          );
        default:
          break;
      }
    }
    return selectedVenue;
  }

  bypassCustomerDetailsWithoutManualEntry() {
    let otherConfigs = this.store.selectSnapshot(WhitelabelState.getOther);
    // this.orgOtherConfigs$.pipe(takeUntil(this.destroy$)).subscribe((otherConfigs)=>{
    if (otherConfigs) {
      this.bypassCustomerDetailsEntry = otherConfigs.bypassCustomerDetailsEntry
        ? otherConfigs.bypassCustomerDetailsEntry
        : false;
    } else {
      this.bypassCustomerDetailsEntry = false;
    }
    // })
  }

  placeOrderWithoutManualEntry() {
    if (this.bypassCustomerDetailsEntry) {
      let vm = this;
      let startedOrderProcessing = true;
      this.modal.showSmallModal(AlertPopupComponent, {
        data: this.dataPassedToPlaceOrderConfirm(),
        whenClosed: (data) => {
          if (data) {
            if (startedOrderProcessing) vm.placeOrder();
          }
          startedOrderProcessing = false;
        },
      });
    }
  }

  setCODOrder() {
    let paymentMethod = paymentMethods.find((method) => method.id == 5);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
  }
  setLoyaltyOrder() {
    let paymentMethod = paymentMethods.find((method) => method.id == 7);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
  }
  checkOrderLevelModifiers() {
    if (this.orderSettings?.isAllowOrderLevelModifier) {
      let modifiers = this.store.selectSnapshot(
        OrderLevelModifierState.getModifiers
      );
      if (modifiers && modifiers.length) {
        let isSelected = modifiers.every(function (element, index) {
          console.log(element);
          if (element && element.isForced) {
            if (element.isInCart) {
              return true;
            } else {
              return false;
            }
          } else return true;
        });

        return isSelected;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  userExit() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.store.dispatch(new ClearCart());
    this.store.dispatch(new ClearLoginDetails());
    this.store.dispatch(new ClearSelectedAddress());
    this.store.dispatch(new ClearDeals());
    if (selectedLocation)
      this.router.navigate([`locations/${selectedLocation._id}/home`]);
    //this.router.navigate([`/locations/${selectedLocation._id}/order`]);
  }

  exitLocation() {
    this.store.dispatch(new ClearCart());
    this.store.dispatch(new ClearSelectedAddress());
    this.store.dispatch(new ClearDeals());
    this.showLocationMissing();
  }

  showLocationMissing() {
    this.sweetAlert
      .confirmWithoutCancel(
        '',
        'Your selected location is closed now.Please select another location'
      )
      .then((result) => {
        this.store.dispatch(new ClearSelectedLocation());
        this.router.navigate([`/locations`]);
      });
  }

  validateQRScan(){
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if(this.qrScanDetails?.venueName){
      if(this.qrScanDetails?.deliveryType == selectedDelivery.textCode && this.qrScanDetails.locationId == this.selectedLocation._id){
        return true;
      }
    } else {
      return true;
    }
    return false
  }

  get isPayLocation() {
    const paymentDetails = this.store.selectSnapshot(PaymentMethodState.getSelectedPaymentType);
    return paymentDetails === 'payLater';
  }

  dataPassedToTokenIssue() {
    const errorText = this.translate.instant('token-issue') ;
    return {
      header: errorText ,
      isHtmlHeader: false,
      closeBtnText:'Ok',
      actionable: false,
    };
}
}
